@import '~normalize.css/normalize.css';

:root {
    --color-bg: #14161A;
    --color-black: #000;
    --color-text: #fff;
    --grey-800: #343A40;
    --grey-300: #DEE2E6;
    --grey-600: #8A919E;
    --grey-900: #212529;
    --fennel-fiasco: #05AA48;
    --color-danger: #DC2C2C;
    --color-overlay: #212529;
    --blue: #00FFF4;
}

