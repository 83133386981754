.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .Mui-expanded {
  background-color: linear-gradient(180deg, #212224 0%, #1F2123 100%);
  background: linear-gradient(180deg, #212224 0%, #1F2123 100%);
} */

.expanded .accordian-btn {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transition: 0.5s;
}

.not-expanded .accordian-btn {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transition: 0.5s;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance:textfield;
}
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

path {
  pointer-events: auto;
}

input:focus {
  outline: none;
}

.css-6o0rhd-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: black !important;
}

.css-6o0rhd-MuiButtonBase-root-MuiTab-root {
  position: relative !important;
  z-index: 99 !important;
  background: transparent !important;
  color: white !important;
  text-transform: capitalize !important;
  font-family: 'Inter' !important;
  font-size: '13px' !important;
  font-weight: 600 !important;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: #67ffff !important;
  height: 100% !important;
  display: flex !important;
  z-index: 1 !important;
  border-radius: 8px !important;
}

.css-1i22262.Mui-selected {
  color: black !important;
}

.css-1i22262 {
  position: relative !important;
  z-index: 99 !important;
  background: transparent !important;
  color: white !important;
  text-transform: capitalize !important;
  font-family: 'Inter' !important;
  font-size: '13px' !important;
  font-weight: 600 !important;
}

.css-ttwr4n {
  background-color: #67ffff !important;
  height: 100% !important;
  display: flex !important;
  z-index: 1 !important;
  border-radius: 8px !important;
}

.css-19ld6u1-MuiModal-root-MuiPopover-root-MuiMenu-root .MuiPaper-root {
  width: 150px;
  border: 1px solid grey;
  border-radius: 7px;
  margin-top: 10px;
  margin-left: -40px;
  padding: 5px;
  background-color: #14161A !important;
}

.css-izuv11 .MuiPaper-root {
  width: 150px;
  border: 1px solid grey;
  border-radius: 7px;
  margin-top: 10px;
  margin-left: -40px;
  padding: 5px;
  background-color: #14161A !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  border: 1px solid #67ffff;
  border-radius: 7px;
  background: linear-gradient(90deg, rgba(0, 255, 244, 0.2), rgba(42, 133, 255, 0.2)) !important;
}

.css-1km1ehz:hover {
  border: 1px solid #67ffff;
  border-radius: 7px;
  background: linear-gradient(90deg, rgba(0, 255, 244, 0.2), rgba(42, 133, 255, 0.2)) !important;
}
